import '../css/CreateVendor.css';
import React, {useCallback, useEffect, useRef, useState} from "react";
import axiosInstance from "../api/axiosConfig";
import IntlTelInput from 'intl-tel-input/react';
import "intl-tel-input/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {error, success} from "@pnotify/core";

const CreateVendor = () => {
    const formRef = useRef();
    const [vendorFirm, setVendorFirm] = useState('');
    const [vendorType, setVendorType] = useState('');
    const [vendorTitle, setVendorTitle] = useState('');
    const [vendorIdentity, setVendorIdentity] = useState('');
    const [vendorTaxHouse, setVendorTaxHouse] = useState('');
    const [vendorMail, setVendorMail] = useState('');
    const [vendorSector, setVendorSector] = useState('');
    const [vendorCurrency, setVendorCurrency] = useState('');
    const [vendorPeriod, setVendorPeriod] = useState('');
    const [vendorIban, setVendorIban] = useState('');
    const [vendorIsSecure, setVendorIsSecure] = useState('');
    const [address, setAddress] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighboor, setNeighboor] = useState([]);
    const [comment, setComment] = useState([]);
    const [phone, setPhone] = useState('');

    const [sectors, setSectors] = useState([]);

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedNeighboor, setSelectedNeighboor] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedCity) {
                    console.log(selectedCity)
                    const response = await axiosInstance.get('/api/users/district?city=' + selectedCity);
                    setDistricts(response.data.response);
                }
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, [selectedCity]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/sector/sectors');
                setSectors(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedDistricts) {
                    const response = await axiosInstance.get('/api/users/county?district=' + selectedDistricts);
                    setNeighboor(response.data.response);
                }
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, [selectedDistricts]);

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const handleDistrictChange = (e) => {
        setSelectedDistricts(e.target.value);
    };

    const handleNeigChange = (e) => {
        setSelectedNeighboor(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/users/cities'); // Backend'den veriyi al
                setCities(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, []);

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'Kayıt Başarılı!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };

    const selectTabs = useCallback((id) => {
        document.querySelectorAll('.the-tabs').forEach(function (element) {
            element.classList.add('d-none');

            if(parseInt(element.getAttribute('data-tabs')) === id) {
                element.classList.remove('d-none');
                document.querySelectorAll('.tabs-btn').forEach(function (el) {
                    el.classList.remove('active');
                })
                document.querySelector(".tabs-btn[data-tabs='"+id+"']").classList.add('active');
            }
        })
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('vendor_firm_id', vendorFirm);
            formData.append('vendor_type', vendorType);
            formData.append('vendor_title', vendorTitle);
            formData.append('vendor_identity', vendorIdentity);
            formData.append('vendor_tax_house', vendorTaxHouse);
            formData.append('vendor_phone', phone);
            formData.append('vendor_mail', vendorMail);
            formData.append('vendor_sector', vendorSector);
            formData.append('vendor_country', 1);
            formData.append('vendor_city', selectedCity);
            formData.append('vendor_district', selectedDistricts);
            formData.append('vendor_neighboor', selectedNeighboor);
            formData.append('vendor_address', address);
            formData.append('vendor_currency', vendorCurrency);
            formData.append('vendor_period', vendorPeriod);
            formData.append('vendor_iban', vendorIban);
            formData.append('vendor_is_secure', vendorIsSecure);
            formData.append('vendor_note', comment);

            const response = await axiosInstance.post('/api/vendors/create-vendor', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            handleSuccessNotification();
        } catch (error) {
            handleErrorNotification(error.message);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <h4 className="pl-1 mb-3">Tedarikçi Oluştur</h4>
                                <button type="submit" className="btn btn-sm btn-primary ml-2"
                                        onClick={handleSubmit}>Kaydet
                                </button>
                            </div>
                        </div>

                        <div className="smart-card p-2">
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="tabs-area">
                                            <button type="button" className="tabs-btn active" data-tabs="0"
                                                    onClick={() => selectTabs(0)}>Tedarikçi Genel Bilgiler
                                            </button>
                                            <button type="button" className="tabs-btn" data-tabs="1"
                                                    onClick={() => selectTabs(1)}>Adres Bilgileri
                                            </button>
                                            <button type="button" className="tabs-btn" data-tabs="2"
                                                    onClick={() => selectTabs(2)}>Ödeme/Muhasebe Bilgileri
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 the-tabs p-0" data-tabs="0">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group d-flex align-items-center no-border">
                                                    <label className="w-50">Satıcı Hitap Türü</label>
                                                    <select className="w-50"
                                                            value={vendorType}
                                                            onChange={(e) => setVendorType(e.target.value)}>
                                                        <option value="">Seçiniz</option>
                                                        <option value="1">Şahıs</option>
                                                        <option value="2">Firma</option>
                                                    </select>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Satıcı Ünvanı</label>
                                                    <div className="w-50">
                                                        <input type="text" placeholder="Ünvan" value={vendorTitle}
                                                               onChange={(e) => setVendorTitle(e.target.value)}/>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Satıcı Vergi NO / TC</label>
                                                    <input className="w-50" type="text" placeholder="****" value={vendorIdentity}
                                                           onChange={(e) => setVendorIdentity(e.target.value)}/>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Satıcı Vergi Dairesi</label>
                                                    <input className="w-50" type="text" placeholder="****" value={vendorTaxHouse}
                                                           onChange={(e) => setVendorTaxHouse(e.target.value)}/>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50" htmlFor="phone">Telefon</label>
                                                    <IntlTelInput className="w-50"
                                                        initialValue={phone}
                                                        onChangeNumber={setPhone}
                                                        initOptions={{
                                                            initialCountry: "tr",
                                                            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js"
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Satıcı Mail Adresi</label>
                                                    <input className="w-50" type="text" placeholder="****@***" value={vendorMail}
                                                           onChange={(e) => setVendorMail(e.target.value)}/>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Satıcı Sektör</label>
                                                    <select className="w-50" value={vendorSector}
                                                            onChange={(e) => setVendorSector(e.target.value)}>
                                                        <option>Seçiniz</option>
                                                        {sectors.map((sector) => (
                                                            <option key={sector.id} value={sector.id}>
                                                                {sector.nace_code +' | ' + sector.sector}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 the-tabs p-0 d-none" data-tabs="1">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50" htmlFor="surname">Ülke</label>
                                                    <input className="w-50"
                                                        type="text"
                                                        id="surname"
                                                        value="Türkiye"
                                                        placeholder="Soyadınız"
                                                        readOnly
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50" htmlFor="city">Satıcı İl</label>
                                                    <select className="w-50" id="city" value={selectedCity} onChange={handleCityChange}>
                                                        <option value="">İl Seçiniz</option>
                                                        {cities.map((city) => (
                                                            <option key={city.id} value={city.id}>
                                                                {city.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50" htmlFor="district">Satıcı İlçe</label>
                                                    <select className="w-50" id="district" value={selectedDistricts}
                                                            onChange={handleDistrictChange}>
                                                        <option value="">İlçe Seçiniz</option>
                                                        {districts.map((district) => (
                                                            <option key={district.id} value={district.id}>
                                                                {district.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50" htmlFor="county">Satıcı Semt</label>
                                                    <select className="w-50" id="county" value={selectedNeighboor}
                                                            onChange={handleNeigChange}>
                                                        <option value="">Semt Seçiniz</option>
                                                        {neighboor.map((neig) => (
                                                            <option key={neig.id} value={neig.id}>
                                                                {neig.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50" htmlFor="adress">Satıcı Adres</label>
                                                    <textarea className="w-50"
                                                        rows={5}
                                                        id="adress"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        required
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 the-tabs p-0 d-none" data-tabs="2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Para Birimi</label>
                                                    <select className="w-50" value={vendorCurrency}
                                                            onChange={(e) => setVendorCurrency(e.target.value)}>
                                                        <option>Seçiniz</option>
                                                        <option value="186">TRY</option>
                                                    </select>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Ödeme Vadesi</label>
                                                    <select className="w-50" value={vendorPeriod}
                                                            onChange={(e) => setVendorPeriod(e.target.value)}>
                                                        <option>Seçiniz</option>
                                                        <option value="3">3</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Bloke</label>
                                                    <div className="d-flex w-50 align-items-center mt-3">
                                                        <input type="checkbox" className="w-25"/>
                                                        <span>Satıcıyı Bloke Et</span>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">IBAN Bilgileri</label>
                                                    <input className="w-50" type="text" placeholder="*********" value={vendorIban}
                                                           onChange={(e) => setVendorIban(e.target.value)}/>
                                                </div>
                                                <div className="form-group d-flex align-items-start no-border">
                                                    <label className="w-50">Güvenilir Satıcı</label>
                                                    <select className="w-50" value={vendorIsSecure}
                                                            onChange={(e) => setVendorIsSecure(e.target.value)}>
                                                        <option>Seçiniz</option>
                                                        <option value="1">Evet</option>
                                                        <option value="0">Hayır</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group d-grid">
                                        <label>Genel Not</label>
                                        <textarea
                                            rows={4}
                                            className="product-note"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <button type="submit" className="btn btn-sm btn-primary mt-3 mb-1">Etiketleri Yazdır
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">Takip Et
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">İşlemler
                                </button>
                            </div>
                        </div>
                        <div className="d-none">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateVendor;