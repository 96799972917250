import '../css/ProductList.css';
import React, { useMemo, useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import axiosInstance from "../api/axiosConfig";
import dayjs from 'dayjs';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

const OfferList = () => {
    const [allPrices, setAllPrices] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);

    const openDetail = () => {
        window.location.href = '/#/purchase-management/offer-detail';
    }

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const response = await axiosInstance.get('/api/currency/get');
                setAllPrices(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get('/api/product/get');
                setAllData(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        Promise.all([fetchPrices(), fetchProducts()]).then(() => setLoading(false));
    }, []);

    const handleButtonClick = (product) => {
        console.log("Butona tıklanan kullanıcı:", product);
    };

    const data = useMemo(
        () => allData.map(product => ({
            ...product,
            created_at: dayjs(product.created_at).format('DD.MM.YYYY HH:mm:ss')
        })),
        [allData]
    );

    const columns = useMemo(() => [
        {
            Header: "Belge No",
            Cell: ({ row }) => {
                return (
                    <span>
                        1234567890
                    </span>
                );
            }
        },
        {
            Header: "Teklif No",
            Cell: ({ row }) => {
                return (
                    <span>
                        1234567890
                    </span>
                );
            }
        },
        {
            Header: "Teklif İsteyen Firma",
            Cell: ({ row }) => {
                return (
                    <span>
                        Kamil Yavuz Sappur Bilişim Hizmetleri AŞ
                    </span>
                );
            }
        },
        {
            Header: "Vergi NO/TC",
            Cell: ({ row }) => {
                return (
                    <span>
                        44444444444
                    </span>
                );
            }
        },
        {
            Header: "Son Teklif Tarihi",
            Cell: ({ row }) => {
                return (
                    <span>
                        32.12.2024
                    </span>
                );
            }
        },
        {
            Header: "Sektör",
            Cell: ({ row }) => {
                return (
                    <span>
                        10 karakter
                    </span>
                );
            }
        },
        {
            Header: "Ürün/İş Tanımı",
            Cell: ({ row }) => {
                return (
                    <span>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
                    </span>
                );
            }
        },
        {
            Header: "İndirim Talebi",
            Cell: ({ row }) => {
                return (
                    <span style={{WebkitFlex: '1 1'}}>
                        <FontAwesomeIcon icon={faDownload}/>
                    </span>
                );
            }
        }
    ], [allPrices]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setPageSize,
        gotoPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        pageOptions
    } = useTable(
        { columns, data },
        usePagination
    );

    if (loading) {
        return <div>Veriler yükleniyor...</div>; // Yüklenme animasyonu gösteriyoruz
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="app-pagination-category-title d-flex align-items-center">
                            <h4 className="pl-1 mb-3">Gelen Teklif Listesi</h4>
                            <button className="btn ml-2 btn-sm btn-primary" disabled="true">Filtreleri Uygula</button>
                        </div>
                        <div className="pagination">
                            <span className="mr-2">Toplam {state.pageSize} Kayıt Gösteriliyor</span>
                            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>
                            <span>
                                Sayfa{' '}
                                <strong>
                                    {state.pageIndex + 1} / {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>
                            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>

                            <select
                                value={state.pageSize}
                                onChange={e => setPageSize(Number(e.target.value))}
                            >
                                {[5, 10, 20, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <form style={{
                        background: "#fff",
                        padding: "10px",
                        borderRadius: "10px",
                        fontSize: "11px"
                    }}>
                        <h6 className="pl-1 mb-3">Teklif Filtreleme</h6>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group no-border">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="bg-transparent"
                                            placeholder="Teklif İsteyen Firma"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group no-border">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            placeholder="Ürün/İş Tanımı"
                                            className="bg-transparent"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div
                                    className="type-area d-flex align-items-center justify-content-start">
                                    <input className="w-0 mr-2" type="radio" name="product_type"
                                           value="mal"/>
                                    <label className="mb-0">Teklif</label>
                                    <input className="w-0 ml-4 mr-2" type="radio" name="product_type"
                                           value="hizmet"/>
                                    <label className="mb-0">İhale</label>
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Sektör</label>
                                    <select
                                        className="w-50 bg-transparent"
                                    >
                                        <option value="">Seçiniz</option>
                                        <option value="1">Birim 1</option>
                                        <option value="2">Birim 2</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Ürün Özellikleri</label>
                                    <select
                                        className="w-50 bg-transparent"
                                    >
                                        <option value="">Seçiniz</option>
                                        <option value="1">Grup 1</option>
                                        <option value="2">Grup 2</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Son Teklif Tarihi</label>
                                    <input
                                        className="w-50 bg-transparent"
                                        type="text"
                                        placeholder="****"
                                    />
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div className="col-md-10">
                    <div className="table-special">
                        <div className="tsol-head">
                            {headerGroups.map(headerGroup => (
                                <span {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <span {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </span>
                                    ))}
                                </span>
                            ))}
                        </div>
                        <div className="ts-body">
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <span onClick={() => openDetail()} className="tsol-item" {...row.getRowProps()} key={row.original.id}>
                                        {row.cells.map(cell => (
                                            <span {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </span>
                                        ))}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfferList;
