import '../css/MasterDataManagement.css';
import React from "react";
import {Link} from 'react-router-dom';
import {faGear, faBars, faEdit, faUser, faPlus, faPray} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PurchaseManagement = () => {
    return (
        <>
            <div className="dashboard">
                <div className="container-fluid p-0 m-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-block mb-4">
                                <h4 style={{
                                    fontSize: "17px",
                                    paddingLeft: "30px",
                                    marginTop: "20px",
                                    borderLeft: "3px solid #d67940",
                                    marginLeft: "2px"
                                }}>Teklif Yönetimi</h4>
                            </div>
                            <div className="app-pagination p-0">
                                <div className="app-item">
                                    <Link to="/purchase-management/offer-request">
                                        <span>Teklif İsteme Ekranı</span>
                                        <FontAwesomeIcon icon={faPray}/>
                                    </Link>
                                </div>
                                <div className="app-item">
                                    <Link to="/purchase-management/offer-list">
                                        <span>Gelen Teklif Listeleme Ve Cevap Verme</span>
                                        <FontAwesomeIcon icon={faPray}/>
                                    </Link>
                                </div>

                                <div className="app-item">
                                    <Link to="/purchase-management/offer-list">
                                        <span>Teklif Karşılaştırma</span>
                                        <FontAwesomeIcon icon={faPray}/>
                                    </Link>
                                </div>

                                <div className="app-item">
                                    <Link to="/purchase-management/offer-list">
                                        <span>İletilen Teklifleri Görüntüleme</span>
                                        <FontAwesomeIcon icon={faPray}/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseManagement;