import '../css/MasterDataManagement.css';
import axiosInstance from "../api/axiosConfig";
import React, {useState, useRef, useEffect, useCallback} from "react";
import {Link} from 'react-router-dom';
import {faAdd, faTimes, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../css/CreateProduct.css';
import "intl-tel-input/styles";
import {error, success} from "@pnotify/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { tr } from 'date-fns/locale';
import Modal from "../elements/Modal";

const OfferRequest = () => {
    const formRef = useRef();
    const [findProduct, setFindProduct] = useState(false);
    const [theProduct, setTheProduct] = useState([]);
    const [partnerCount, setPartnerCount] = useState(1);
    const [reqType, setReqType] = useState('');
    const [allProduct, setAllProduct] = useState([]);
    const [selectedAllProduct, setSelectedAllProduct] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showPropertiesModal, setShowPropertiesModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [productCode, setProductCode] = useState("");
    const [selectFindingProduct, setSelectFindingProduct] = useState("");
    const [finding, setFinding] = useState(false);
    const [products, setProducts] = useState([]);
    const [requestCode, setRequestCode] = useState("");
    const [productTitle, setProductTitle] = useState("");
    const [vendorType, setVendorType] = useState(1);
    const [productGroup, setProductGroup] = useState("");
    const [manufacturerNo, setManufacturerNo] = useState("");
    const [manufacturerTitle, setManufacturerTitle] = useState("");
    const [barcode, setBarcode] = useState("");
    const [kdv, setKdv] = useState("");
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [isNote, setisNote] = useState(false);
    const [serialNoTracking, setSerialNoTracking] = useState(false);
    const [purchasePrice, setPurchasePrice] = useState("");
    const [salesPrice, setSalesPrice] = useState("");
    const [purchasePriceCurrency, setPurchasePriceCurrency] = useState("TRY");
    const [salesPriceCurrency, setSalesPriceCurrency] = useState("TRY");
    const [comment, setComment] = useState("");
    const [vendors, setVendors] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const generateRequestCode = () => {
        if(requestCode === '') {
            const randomCode = 'REQ-' + Math.floor(100000 + Math.random() * 900000); // Örnek ürün kodu: PRD-123456
            setRequestCode(randomCode);
        }
    };

    useEffect( (e) => {
        if(showUserModal === false) {
            setPartnerCount(1)
        }
    }, [showUserModal]);

    useEffect( (e) => {
        setFinding(true);
        async function fetchData() {
            if(productCode !== '') {
                const response = await axiosInstance.get('/api/product/find?product_code=' + productCode);
                setProducts(response.data.response)
            }else {
                setProducts([])
            }
            setFinding(false);
        }

        fetchData();
    }, [productCode]);

    useEffect( (e) => {
        setFinding(false);
        async function fetchData() {
            if(productCode !== '') {
                const response = await axiosInstance.get('/api/product/take-product?product_code=' + productCode);
                setFindProduct(true)
                setTheProduct(response.data.response);

                setAllProduct(prevProducts => [...prevProducts, response.data.response]);

                removeProduct();
            }
        }

        fetchData();
    }, [selectFindingProduct]);

    const addProducts = async () => {
        setSelectedAllProduct(allProduct)
        setShowModal(false);
    }
    const selectProduct = async (id) => {
        setProductCode(id)
        setSelectFindingProduct(id);
    }

    const removeProduct = async (id) => {
        setProductCode("")
    }

    generateRequestCode();
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();

            formData.append('comment', comment);

            const response = await axiosInstance.post('/api/product/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            handleSuccessNotification();
            formRef.current.reset();
        } catch (error) {
            handleErrorNotification(error.message);
        }
    };



    const selectTabs = useCallback((id) => {
        document.querySelectorAll('.the-tabs').forEach(function (element) {
            element.classList.add('d-none');

            if(parseInt(element.getAttribute('data-tabs')) === id) {
                element.classList.remove('d-none');
                document.querySelectorAll('.tabs-btn').forEach(function (el) {
                    el.classList.remove('active');
                })
                document.querySelector(".tabs-btn[data-tabs='"+id+"']").classList.add('active');
            }
        })
    }, []);

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'Kayıt Başarılı!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };


    const addpartner = () => {
        setPartnerCount(partnerCount + 1);
    };

    const handlePropertiesButtonClick = (user) => {
        setShowPropertiesModal(!showPropertiesModal);
    };

    const handleUserButtonClick = (user) => {
        setShowUserModal(!showUserModal);
    };


    const handleButtonClick = (user) => {
        setShowModal(!showModal);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <h4 className="pl-1 mb-3">Teklif İsteme</h4>
                                <button type="submit" className="btn btn-sm btn-primary ml-2"
                                        onClick={handleSubmit}>Kaydet
                                </button>
                            </div>
                        </div>
                        <div className="smart-card">
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group no-border d-flex align-items-center">
                                                <label className="w-50">Tedarik Kaynağını Seç</label>
                                                <select
                                                    value={vendorType}
                                                    onChange={(e) => setVendorType(e.target.value)}
                                                    className="w-50"
                                                >
                                                    <option value="1">Tedarikçi Seç</option>
                                                    <option value="2">Sektördeki Tüm Tedarikçiler</option>
                                                    <option value="3">Kendi Tedarikçilerimden İste</option>
                                                </select>

                                            </div>
                                        </div>
                                        {
                                            parseInt(vendorType) === 1 ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="form-group no-border d-flex align-items-center">
                                                            <label className="w-50">Satıcı/Tedarikçi</label>
                                                            <select
                                                                value={vendors}
                                                                onChange={(e) => setVendors(e.target.value)}
                                                                className="w-50"
                                                            >
                                                                <option value="">Seçiniz</option>
                                                                <option value="1">Birim 1</option>
                                                                <option value="2">Birim 2</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                </>
                                            ) : ''
                                        }
                                    </div>

                                </div>
                                <div className="col-md-4 pt-3">
                                    <div className="form-group mb-0 no-border pl-1 pr-1">
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="text"
                                                placeholder="Teklif Numarası"
                                                value={requestCode}
                                                onChange={(e) => setRequestCode(e.target.value)}
                                                readOnly
                                            />
                                        </div>
                                        <p className="mb-0 text-danger"
                                           style={{fontSize: '10px', marginTop: '2px'}}><b>Teklif Numarası</b> Otomatik
                                            olarak
                                            oluşturulmuştur.</p>
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex justify-content-end">
                                    <div>
                                        <div className="image-area" onClick={() => {
                                            document.querySelector('.file-input').click()
                                        }}>
                                            <FontAwesomeIcon icon={faUpload}/>
                                        </div>
                                        <input type="file" className="d-none file-input"
                                               onChange={(e) => setImage(e.target.files[0])}/>
                                        <p className="mb-0 text-center text-danger"
                                           style={{fontSize: '10px', marginTop: '2px'}}>Dosya Ekle</p>
                                    </div>
                                </div>


                                <div className="col-12 mb-3">
                                    <div className="tabs-area">
                                        <button type="button" className="tabs-btn active" data-tabs="0"
                                                onClick={() => selectTabs(0)}>Teklif/İhale Bilgileri
                                        </button>
                                        <button type="button" className="tabs-btn" data-tabs="1"
                                                onClick={() => selectTabs(1)}>Ödeme Bilgileri
                                        </button>
                                        <button type="button" className="tabs-btn" data-tabs="2"
                                                onClick={() => selectTabs(2)}>Muhattap/İletişim Bilgileri
                                        </button>
                                        {
                                            parseInt(reqType) === 2 ?
                                                (
                                                    <>
                                                        <button type="button" className="tabs-btn" data-tabs="3"
                                                                onClick={() => selectTabs(3)}>İhale Bilgileri
                                                        </button>
                                                    </>
                                                ) : ''
                                        }
                                    </div>
                                </div>
                                <div className="col-12 the-tabs" data-tabs="0">
                                    <div className="row">
                                        <div className="col-md-6 p-0">
                                            <div className="form-group no-border d-flex align-items-center col-12">
                                                <label className="w-50">Ürün/İş Tanımı</label>
                                                <input
                                                    className="w-50"
                                                    type="text"
                                                    placeholder="*********"
                                                    value={brand}
                                                    onChange={(e) => setBrand(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group no-border d-flex align-items-center col-12">
                                                <label className="w-50">Tedarik Tipi</label>
                                                <select
                                                    value={reqType}
                                                    onChange={(e) => setReqType(e.target.value)}
                                                    className="w-50"
                                                >
                                                    <option value="1">Teklif</option>
                                                    <option value="2">İhale</option>
                                                </select>
                                            </div>
                                            <div className="form-group no-border d-flex align-items-center col-12">
                                                <label className="w-75">Not Ekle</label>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={isNote}
                                                        onChange={() => setisNote(!isNote)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group no-border d-flex align-items-center col-12">
                                                <label className="w-50">Sektör</label>
                                                <select
                                                    value={vendors}
                                                    onChange={(e) => setVendors(e.target.value)}
                                                    className="w-50"
                                                >
                                                    <option value="">Seçiniz</option>
                                                    <option value="1">Birim 1</option>
                                                    <option value="2">Birim 2</option>
                                                </select>
                                            </div>
                                            <div className="form-group no-border d-flex align-items-center col-12">
                                                <label className="w-50">Son Teklif Tarihi</label>
                                                <DatePicker
                                                    className="w-100"
                                                    selected={selectedDate}
                                                    onChange={date => setSelectedDate(date)}
                                                    dateFormat="dd.MM.yyyy"
                                                    locale={tr}
                                                />
                                            </div>
                                            <div className="form-group no-border d-flex align-items-center col-12">
                                                <label className="w-50">Teslim Şekli</label>
                                                <select
                                                    className="w-50"
                                                    value={manufacturerTitle}
                                                    onChange={(e) => setManufacturerTitle(e.target.value)}
                                                >
                                                    <option value="">Seçiniz</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 p-0 the-tabs d-none" data-tabs="1">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div
                                                    className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Ödeme Koşulu</label>
                                                    <div className="w-50 d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            placeholder="Koşul"
                                                            className="w-100"
                                                            value={purchasePrice}
                                                            onChange={(e) => setPurchasePrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Ödeme Tipi</label>
                                                    <div className="w-50 d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            placeholder="Tip"
                                                            className="w-100"
                                                            value={purchasePrice}
                                                            onChange={(e) => setPurchasePrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Ödeme Para Birimi</label>
                                                    <div className="w-50 d-flex align-items-center">
                                                        <select
                                                            value={salesPriceCurrency}
                                                            onChange={(e) => setSalesPriceCurrency(e.target.value)}
                                                        >
                                                            <option>TRY</option>
                                                            <option>EUR</option>
                                                            <option>USD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Teklif Para Birimi</label>
                                                    <div className="w-50 d-flex align-items-center">
                                                        <select
                                                            value={salesPriceCurrency}
                                                            onChange={(e) => setSalesPriceCurrency(e.target.value)}
                                                        >
                                                            <option>TRY</option>
                                                            <option>EUR</option>
                                                            <option>USD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 the-tabs d-none" data-tabs="2">
                                    <div className="row">
                                        <div className="col-md-6 p-0">
                                            <button type="button" onClick={handleUserButtonClick} className="btn btn-sm btn-light mb-3">Muhattap Ekle</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 the-tabs d-none" data-tabs="3"></div>
                                {
                                    isNote ? (
                                        <>
                                            <div className="col-12 form-group d-grid">
                                                <label>Teklif Notu*</label>
                                                <textarea
                                                    rows={4}
                                                    className="product-note"
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                />
                                            </div>
                                        </>
                                    ) : ''
                                }
                            </div>
                        </form>
                        </div>

                        <div className="smart-card mt-2">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center">
                                    <h6 className="d-flex align-items-end mt-2">İstenilen Ürün Bilgileri</h6>
                                    <button className="btn btn-sm ml-2 btn-primary" onClick={handleButtonClick}>Ürün
                                        Ekle
                                    </button>
                                </div>

                                <div className="col-12">
                                    <div className="product-area">

                                        <div className="product-item border-bottom d-flex align-items-center">
                                            <div className="requestCode">
                                                <span>Teklif Numarası</span>
                                            </div>
                                            <div className="requestCode">
                                                <span>Klm. Num.</span>
                                            </div>

                                            <div className="productName">
                                                <span>Ürün Kodu</span>
                                            </div>

                                            <div className="productCode">
                                                <span>Ürün Tanımı</span>
                                            </div>
                                            <div className="count">
                                                <span>Miktar</span>
                                            </div>

                                            <div className="measure">
                                                <span>Birim</span>
                                            </div>

                                            <div className="measure">
                                                <span>P.Birimi</span>
                                            </div>

                                            <div className="request-last-date">
                                                <span>Teslim Tarihi</span>
                                            </div>

                                            <div className="product-options">
                                                <span>Ürün Özel.</span>
                                            </div>

                                            <div className="product-options">
                                                <span>Doc.</span>
                                            </div>

                                            <div className="request-last-date">
                                                <span>Not</span>
                                            </div>
                                        </div>


                                        {selectedAllProduct.map(e => {
                                            return (
                                                <>
                                                    <div className="product-item d-flex align-items-center">
                                                        <div className="requestCode">
                                                            <span>{requestCode}</span>
                                                        </div>

                                                        <div className="productCode">
                                                            <span>{e[0].product_code}</span>
                                                        </div>

                                                        <div className="productCode">
                                                            <span>{e[0].product_code}</span>
                                                        </div>

                                                        <div className="productName">
                                                            <span>{e[0].product_title}</span>
                                                        </div>
                                                        <div className="count">
                                                            <input type="text"/>
                                                        </div>

                                                        <div className="measure">
                                                            <span>{e[0].measure_unit}</span>
                                                        </div>

                                                        <div className="currency">
                                                            <input type="number"
                                                                   placeholder={e[0].purchase_price_currency}/>
                                                        </div>

                                                        <div className="request-last-date">
                                                            <input type="text"/>
                                                        </div>

                                                        <div className="product-options">
                                                            <button onClick={handlePropertiesButtonClick} className="btn btn-sm"
                                                                    style={{fontSize: '10px'}}>Ekle
                                                            </button>
                                                        </div>

                                                        <div className="document">
                                                            <button className="btn btn-sm"
                                                                    style={{fontSize: '10px'}}>Ekle
                                                            </button>
                                                        </div>

                                                        <div className="request-note">
                                                            <button className="btn btn-sm"
                                                                    style={{fontSize: '10px'}}>Ekle
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <button type="submit" className="btn btn-sm btn-primary mt-3 mb-1">Etiketleri Yazdır
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">Takip Et
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">İşlemler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onClose={handleButtonClick}>
                <h6 className="border-bottom pb-2">Ürün Ekle</h6>
                <div>
                    <div className="form-group no-border alertt pl-1 pr-1">
                        <div className="d-flex align-items-center">
                            <div className="inputcontainer w-100">
                                <input
                                    style={{fontSize: "12px"}}
                                    type="text"
                                    className="form-control"
                                    placeholder="Ürün Kodu ile Arama Yapın"
                                    value={productCode}
                                    onChange={(e) => setProductCode(e.target.value)}
                                    readOnly={selectFindingProduct === '' ? '' : "readonly"}
                                />
                                {selectFindingProduct === "" && products.length > 0 ? (
                                    <>
                                        <div className="p-dropdown">
                                            {products.map(e => {
                                                return (
                                                    <>
                                                        <div className="pd-item"
                                                             onClick={() => selectProduct(e.product_code)}>
                                                            <span>{e.product_code}</span>
                                                            <spant>{e.brand}</spant>
                                                            <span>{e.model}</span>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </>
                                ) : ""}
                                {finding ? (
                                    <>
                                        <div className="icon-container">
                                            <i className="loader-mini"></i>
                                        </div>
                                    </>
                                ) : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {allProduct.map(e => {
                        return (
                            <>
                                <div className="pd-item"
                                     onClick={() => selectProduct(e[0].product_code)}>
                                    <span>{e[0].product_code}</span>
                                    <span>{e[0].brand}</span>
                                    <span>{e[0].model}</span>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="d-flex w-100 align-items-center">
                    <button className="btn btn-sm w-100 mt-3 btn-primary" onClick={addProducts}>Kaydet</button>
                </div>
            </Modal>

            <Modal show={showPropertiesModal} width="900" onClose={handlePropertiesButtonClick}>
                <h6 className="border-bottom pb-2">Özellik Ekle</h6>
                <div className="partner-area">
                    <div className="partner-head">
                        <span>İstenen Özellik Tanımı</span>
                        <span>İstenen Değer</span>
                        <span>Karşılanan Değer</span>
                        <span>Ekle</span>
                    </div>

                    {new Array(partnerCount).fill(null).map((_, index) => (
                        <div className="partner-item mt-2">
                            <span><input type="text"/></span>
                            <span><input type="text"/></span>
                            <span><input type="text"/></span>
                            <span onClick={addpartner}><FontAwesomeIcon icon={faAdd}/></span>
                        </div>
                    ))}
                </div>
                <div className="d-flex w-100 align-items-center">
                    <button className="btn btn-sm w-100 mt-3 btn-primary" onClick={addProducts}>Kaydet</button>
                </div>
            </Modal>


            <Modal show={showUserModal} width="900" onClose={handleUserButtonClick}>
                <h6 className="border-bottom pb-2">Muhattap Ekle</h6>
                <div className="partner-area">
                    <div className="partner-head">
                        <span>Teklif Sahibi</span>
                        <span>Mail</span>
                        <span>İletişim/Telefon</span>
                        <span>Ekle</span>
                    </div>

                    {new Array(partnerCount).fill(null).map((_, index) => (
                        <div className="partner-item mt-2">
                            <span><input type="text"/></span>
                            <span><input type="text"/></span>
                            <span><input type="text"/></span>
                            <span onClick={addpartner}><FontAwesomeIcon icon={faAdd}/></span>
                        </div>
                    ))}
                </div>
                <div className="d-flex w-100 align-items-center">
                    <button className="btn btn-sm w-100 mt-3 btn-primary" onClick={addProducts}>Kaydet</button>
                </div>
            </Modal>
        </>
    );
};

export default OfferRequest;